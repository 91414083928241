// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';  // 导入 Home 组件
import Page from './Page';  // 导入 Page 组件

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/page" element={<Page />} />
        </Routes>
    );
}

export default App;
